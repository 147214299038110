@charset "UTF-8";
/** Ionic CSS Variables **/
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
:root {
  /** primary **/
  --ion-default-font: "Manrope", sans-serif !important;
  --ion-font-family: "Manrope", sans-serif !important;
  --ion-color-primary: #151945;
  --ion-color-primary-rgb: 52, 73, 96;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #151945;
  --ion-color-primary-tint: #151945;
  /** Sand **/
  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 227, 218, 208;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ffffff;
  --ion-color-secondary-tint: #ffffff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-monaai: #072966;
  --ion-color-monaai-rgb: 236, 146, 26;
  --ion-color-monaai-contrast: #000000;
  --ion-color-monaai-contrast-rgb: 0, 0, 0;
  --ion-color-monaai-shade: #d7d8da;
  --ion-color-monaai-tint: #f5f6f9;
}
:root .ion-color-monaai {
  --ion-color-base: #072966 !important;
  --ion-color-base-rgb: 236, 146, 26 !important;
  --ion-color-contrast: #000000 !important;
  --ion-color-contrast-rgb: 0, 0, 0 !important;
  --ion-color-shade: #1e2023 !important;
  --ion-color-tint: #383a3e !important;
}

.disclaimer-popover {
  /* The popover content area */
}
.disclaimer-popover .popover-wrapper {
  width: 400px;
  /* Adjust the width as needed */
  max-height: 600px;
  /* Restrict the height if desired */
}
.disclaimer-popover .popover-content {
  /* If the content area also needs styling, do it here */
  background: rgba(19, 27, 74, 0.9);
  /* Example: match your disclaimer’s dark overlay */
  border-radius: 12px;
}

.customCompanyAlert {
  /* The popover content area */
}
.customCompanyAlert .popover-wrapper {
  width: 400px;
  /* Adjust the width as needed */
  max-height: 600px;
  /* Restrict the height if desired */
}
.customCompanyAlert .popover-content {
  /* If the content area also needs styling, do it here */
  background: rgba(19, 27, 74, 0.9);
  /* Example: match your disclaimer’s dark overlay */
  border-radius: 12px;
}

ion-popover {
  --width: 25%;
  --min-width:450px;
  --border-radius:25px;
}