/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import 'swiper/swiper-bundle.min.css';
/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
body {
    font-family: 'Roboto', sans-serif;
}

:root {
    --ion-default-font: 'Manrope', sans-serif !important;
}

.my-modal-class {
    .modal-wrapper {
        height: 30%;
        width: 90%;
        border-radius: 15px;
    }
}

ion-content {
    --background: #f6f6f6;
    font-style: var(--ion-default-font);
}

.minimalWidth .alert-wrapper {
    width: 400px;
}

.edit-message-modal {
    &::part(content) {
        border-radius: 15px;
    }
    --height: 52%; //Ray did it for testing out the modal flexibly
}

.emailModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        max-width: 550px;
        border-radius: 10px;
        width: 50%;
        height: 60%;
        margin: auto;
    }
}

.deleteAccModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        border-radius: 10px;
        width: 500px;
        height: 250px;
        margin: auto;
    }
}

.logoModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        max-width: 550px;
        border-radius: 10px;
        width: 50%;
        height: 60%;
        margin: auto;
    }
}

.ApplicationInformationPageModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        width: 90%;
        height: 90%;
        border-radius: 10px;
        margin: auto;
    }
}

.moreSettingsModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        max-width: 250px;
        border-radius: 10px;
        width: 50%;
        height: 40%;
        margin: auto;
    }
}

.deleteJobOfferModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        max-width: 550px;
        border-radius: 10px;
        // width: 70%;
        height: 40%;
        margin: auto;
    }
}

.configureMessageModal {
    &::part(content) {
        backdrop-filter: blur(12px);
        max-width: 550px;
        border-radius: 10px;
        // width: 70%;
        height: 35%;
        margin: auto;
    }
}

.flowchart-dialog {
    --width: 95% !important;
    --height: 95% !important;
}

ion-header {
    filter: drop-shadow(2px 4px 6px lightgray);
    font-weight: 500 !important;
}

.toast-class {
    --border-color: #e1e4ea;
    --border-radius: 10px;
    --border-style: solid;
    --border-width: 1px;
    --background: #ffffff;
    --height: 70px;
    --max-width: 510px;
    z-index: 10009 !important;
    margin-left: 13%;
}

button.alert-button.alert-button-confirm {
    background-color: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
    border-radius: 25px;
}

.draft-toast-class {
    --border-color: #e1e4ea;
    --border-radius: 10px;
    --border-style: solid;
    --border-width: 1px;
    --background: #ffffff;
    --height: 70px;
    --width: 400px;
    margin-left: 13%;
    z-index: 10009 !important;
}

.kendoTest .k-button {
    border-width: 0px !important;
    border-style: none !important;
    padding-bottom: 12px;
    padding-right: 1px;
}

.kendoTest .k-tabstrip-items-wrapper {
    background: #f6f6f6
}

.MatchingPopover {
    --border-radius: 10px;
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
    --width: 90%;
    --max-width: 600px;
    --height: auto;
    --min-height: 600px;
}

.ApplicantPopover {
    // --border-radius: 10px;
    // --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    // --padding-top: 20px;
    // --padding-bottom: 20px;
    // --padding-start: 20px;
    // --padding-end: 20px;
    --width: 90%;
    --max-width: 1200px;
    // --height: auto;
    // --min-height: 800px;
}

.notifications-center-modal{
    --border-radius: 10px;
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
    --width: 1000px !important;
    --min-height: 800px !important;
}

.alertForChoosingWorkflows {
    --max-width: 400px; /* Set a maximum width */
    
    /* Apply direct height properties */
    min-height: 400px !important; /* Enforce a minimum height */
    height: auto !important; /* Allow height to adjust based on content */
    
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
    
    --background: #ffffff; /* Set a white background */
    --border-radius: 12px; /* Rounded corners */
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    
    overflow-y: auto; /* Enable scrolling if content overflows */
}

.team-builder-modal {
    --border-radius: 10px;
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
    --width: 1000px !important;
    --min-height: 800px !important;
}

.customCompanyAlert {
    --width: 90%; /* Adjust the width as needed */
    --max-width: 800px; /* Set a maximum width */
    
    /* Apply direct height properties */
    min-height: 800px !important; /* Enforce a minimum height */
    height: auto !important; /* Allow height to adjust based on content */
    
    --padding-top: 20px;
    --padding-bottom: 20px;
    --padding-start: 20px;
    --padding-end: 20px;
    
    --background: #ffffff; /* Set a white background */
    --border-radius: 12px; /* Rounded corners */
    --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    
    overflow-y: auto; /* Enable scrolling if content overflows */
  }
  
  /* Style the alert header */
  .customCompanyAlert .alert-header {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* Style the alert message */
  .customCompanyAlert .alert-message {
    font-size: 1.1em;
    color: #333333;
  }
  
  /* Style links within the alert */
  .customCompanyAlert .alert-message a {
    color: #3880ff; /* Ionic primary color */
    text-decoration: none;
  }
  
  .customCompanyAlert .alert-message a:hover {
    text-decoration: underline;
  }
  
  /* Grid Layout */
  .customCompanyAlert .company-details-grid {
    display: grid;
    grid-template-columns: 1fr 2fr; /* Two columns: label and value */
    gap: 10px 20px; /* Row and column gaps */
  }
  
  .customCompanyAlert .label {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #555555;
  }
  
  .customCompanyAlert .label .icon {
    margin-right: 8px;
    font-size: 1.2em; /* Adjust emoji size */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 800px) {
    .customCompanyAlert {
      --width: 95%;
      --max-width: 95%;
      
      /* Adjust height for smaller screens */
      min-height: 800px !important;
      height: auto !important;
    }
  
    .customCompanyAlert .company-details-grid {
      grid-template-columns: 1fr; /* Single column on small screens */
    }
  
    .customCompanyAlert .label,
    .customCompanyAlert .value {
      grid-column: span 2; /* Span both columns */
    }
  
    .customCompanyAlert .label {
      margin-top: 10px; /* Add spacing for better readability */
    }
  
    .customCompanyAlert .label .icon {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 600px) {
    .customCompanyAlert {
      --width: 95%;
      --max-width: 95%;
    }
  
    .customCompanyAlert .company-details-grid {
      grid-template-columns: 1fr; /* Single column on small screens */
    }
  
    .customCompanyAlert .label,
    .customCompanyAlert .value {
      grid-column: span 2; /* Span both columns */
    }
  
    .customCompanyAlert .label {
      margin-top: 10px; /* Add spacing for better readability */
    }
  
    .customCompanyAlert .label .icon {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }

.test {
    width: 300px;
    font-size: 200px;
}

video-modal {
    width: 80%;
    height: 80%;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

// --- CSS for the  Custom Ion Content
.custom-ion-content {
    &::part(scroll) {
        overflow-y: hidden;
    }
    --background: #fff;
}

// --- CSS for the  Custom scrollable div
.scrollableDiv {
    height: 99%;
    overflow-y: auto;
    margin: 4px;
    margin-left: 0 !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/*  change style of scrollbar in ion popover */
::-webkit-scrollbar-thumb {
    background: #151945;
    border-radius: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #151945;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    // background: #151945;
    border-radius: 10px;
    // background: linear-gradient(90deg, hsla(236, 100%, 3%, 1) 0%, hsla(235, 51%, 33%, 1) 100%);
    background: linear-gradient(90deg, hsla(236, 100%, 3%, 1) 15%, hsla(227, 48%, 41%, 1) 100%);
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #afafb6;
}


div.k-grid-header {
    padding: 0px 0px 0px 0px !important;
    font-weight: 500 !important;
}

.k-tabstrip {
    overflow-y: hidden !important;
}

.k-i-loading {
    color: var(--ion-color-primary) !important;
}

.k-tabstrip-content.k-active,
.k-tabstrip>.k-content.k-active {
    overflow-y: hidden !important;
}

.k-grid {
    color: #000 !important;
}

.k-grid-content {
    overflow-y: auto !important;
}

.k-tabstrip-items {
    font-weight: 500 !important;
    font-size: 15px !important;
}

.k-grid-norecords td {
    padding: 0px !important;
}

.k-grid td:first-child {
    // css for kendogrid first column used for job-offers & applicants for checkbox
    padding: 0;
    padding-top: 27px;
    padding-left: 8px;
}

.k-loading-image::before {
    // css for kendogrid loading image before used for job-offers & applicants
    border-width: clamp(0.015em, 1.5px, 1.5px) !important;
    font-size: 5em !important;
    color: var(--ion-color-primary);
    left: 45%;
    top: 38%;
}

.k-checkbox-md {
    // kendo checkbox size increase for job-offers & applicants
    width: 23px !important;
    height: 23px !important;
}

.k-loading-image::after {
    // css for kendogrid loading image after used for job-offers & applicants
    border-width: clamp(0.015em, 1.5px, 1.5px) !important;
    font-size: 5em !important;
    color: var(--ion-color-primary);
    left: 45%;
    top: 38%;
}

.walkthrough-popover-class {
    .popover-content {
        background: #1e1e2f;
        color: #fff;
        padding: 16px;
      }

      .walkthrough-container {
        max-width: 250px;
        text-align: left;
      }
}

.split-pane-visible > .split-pane-side.collapsed {
    min-width: 75px !important;
    max-width: 75px !important;
}


// @font-face {
//     font-family: "DejaVu Sans";                                      // this makes arabic work, but does not support hindi and malayalam
//     src: url("/assets/fonts/DejaVuSans.ttf") format("truetype");
//   }

  
//.......................................................................................

@font-face {
    font-family: 'NotoSans-Regular';
    src: url('/assets/fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansDevanagari-Regular';
    src: url('/assets/fonts/NotoSansDevanagari-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansSC-Regular';
    src: url('/assets/fonts/NotoSansSC-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'NotoSansTC-Regular';
    src: url('/assets/fonts/NotoSansTC-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSansMalayalam-Regular';
    src: url('/assets/fonts/NotoSansMalayalam-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DejaVu Sans";                                      // this makes arabic work, but does not support hindi and malayalam
    src: url("/assets/fonts/DejaVuSans.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

// @font-face {
//     font-family: 'NotoSansArabic-Regular';
//     src: url('/assets/fonts/NotoSansArabic-Regular.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

//.......................................................................................

// @font-face {
//     font-family: 'NotoSansMalayalam-Regular';
//     src: url('/assets/fonts/NotoSansMalayalam-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'NotoSansArabic-Variable';
//     src: url('/assets/fonts/NotoSansArabic-VariableFont_wdth,wght.ttf') format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'NotoSansMalayalam-Regular';
//     src: url('/assets/fonts/NotoSansMalayalam-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

